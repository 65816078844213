import './styles.scss';
import React, {memo, useEffect, useMemo, useState} from 'react';
import Slider from "react-slick";
import {Link, navigate} from "gatsby";

import {
    slot1,
    slot2,
    slot3,
    slot4,
    slot5,
    slot6,
    slot7,
    slot21,
    slot22,
    slot23,
    slot24,
    slot25,
    slot26,
    slot27,
    slot31,
    slot32,
    slot33,
    slot34,
    slot35,
    slot36,
    slot37,
    slot41,
    slot42,
    slot43,
    slot44,
    slot45,
    slot46,
    slot47,
    slot51,
    slot52,
    slot53,
    slot54,
    slot55,
    slot56,
    slot57,
    slot61,
    slot62,
    slot63,
    slot64,
    slot65,
    slot66,
    slot67,
    slot71,
    slot72,
    slot73,
    slot74,
    slot75,
    slot76,
    slot77,
    slot81,
    slot82,
    slot83,
    slot84,
    slot85,
    slot86,
    slot87,
    slot91,
    slot92,
    slot93,
    slot94,
    slot95,
    slot96,
    slot97,
    slot101,
    slot102,
    slot103,
    slot104,
    slot105,
    slot106,
    slot107,
    Crown2,
    arrRight2,
    apple,
} from "../../images";

type Props = {
    fullLink: string,
    pagePath: string
}

// const settingsMob = {
//     dots: true,
//     infinite: true,
//     speed: 400,
//     slidesToShow: 1,
//     slidesToScroll: 1
// };

const LearnBlockFeelGrow = ({fullLink, pagePath}: Props) => {

    const [currSlide, setCurrSlide] = useState('1');

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 1,
        // beforeChange: function (currentSlide:string, nextSlide:string) {
        //     console.log("before change", currentSlide, nextSlide);
        // },
        afterChange: function (currentSlide:string) {
            setCurrSlide(currentSlide+1);
        },
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    adaptiveHeight: true,
                }
            }
        ]
        // afterChange: handleAfterChange(currentSlide);
    };

    const [tabs, setTabs] = useState([
        {
            name: 'We recommend',
            type: 'type-1',
        },
        {
            name: 'Interpersonal skills',
            type: 'type-2',
        },
        {
            name: 'Creativity',
            type: 'type-3',
        },
        {
            name: 'Leadership',
            type: 'type-4',
        },
        {
            name: 'Public speaking',
            type: 'type-5',
        },
        {
            name: 'How to manage stress',
            type: 'type-6',
        },
        {
            name: 'Career',
            type: 'type-7',
        },
        {
            name: 'Personal finances',
            type: 'type-8',
        },
        {
            name: 'Critical thinking',
            type: 'type-9',
        },
        {
            name: 'Brain Training',
            type: 'type-10',
        }
    ]);

    const [items, setItems] = useState([
        {
            image: slot1,
            title: 'How to get out of the debt trap',
            text: 'In 10 minutes, you’ll learn how to avoid bad credit and navigate your way out of debt traps and how best to manage your income and expenses.',
            category: 'type-1',
        },
        {
            image: slot2,
            title: 'How to speak confidently and deliver your message',
            text: 'Speaking confidently is good for effective communication. It lets you connect with your audience. You will learn how to deliver a speech without a fuss.',
            category: 'type-1',
        },
        {
            image: slot3,
            title: 'Develop perseverance and resilience with these easy steps',
            text: 'Are you tired of giving up when things get tough? Join this class and learn how to cope with it and follow through with your goals to be successful.',
            category: 'type-1',
        },
        {
            image: slot4,
            title: 'How to manage a team of creatives and succeed?',
            text: 'Are you searching for ways to manage your team of creatives better? This course is about the management strategies you need to use to promote creativity in your team.',
            category: 'type-1',
        },
        {
            image: slot5,
            title: 'How to improve your presentation skills?',
            text: 'Do you fear presenting in the public? This is a common problem many people experience. Join this class and learn how to present your ideas confidently!',
            category: 'type-1',
        },
        {
            image: slot6,
            title: 'Budgeting of FeelGrow.AI',
            text: 'Start planning a monthly budget, increasing your savings with the budgeting 101 mini-course.',
            category: 'type-1',
        },
        {
            image: slot7,
            title: 'Improving & Managing your focus with FeelGrow.AI',
            text: 'Train your focus management with FeelGrow.AI mini-course and excel in all walks of life.',
            category: 'type-1',
        },
        {
            image: slot21,
            title: '6 Best self-improvement movies: Watch and be inspired',
            text: 'The best films that will inspire you to change something inside yourself and the way you look at the world.',
            category: 'type-2',
        },
        {
            image: slot22,
            title: 'Three-act structure: Classic way to talk about anything in a fun way',
            text: 'Learn one of the most effective storytelling techniques with our "Three-Act Structure" mini-course.',
            category: 'type-2',
        },
        {
            image: slot23,
            title: 'Microlearning as a path to super-achievement',
            text: 'Microlearning is the teaching of a small amount of knowledge or skills in a time frame of 5 minutes.',
            category: 'type-2',
        },
        {
            image: slot24,
            title: '7 Liberal arts: From antiquity to modern iimes',
            text: 'Discover the pillars of science, that helped humanity to achieve & invent, in this mini-course.',
            category: 'type-2',
        },
        {
            image: slot25,
            title: 'Storytelling',
            text: 'Studying 3-4 times a week, you will learn to express your ideas in a fascinating, reasoned, original and ingenious way, so that you would be interesting to read and listen to.',
            category: 'type-2',
        },
        {
            image: slot26,
            title: 'Human psychology',
            text: 'This course consists of lessons containing interesting theoretical information about human psychology, and a number of practical tips are given.',
            category: 'type-2',
        },
        {
            image: slot27,
            title: 'Mass communications',
            text: 'You will find out the secrets of bloggers and politicians for effective communication with an impersonal audience, discover how to influence a broad audience.',
            category: 'type-2',
        },
        {
            image: slot31,
            title: 'Self-irony: Laugh at yourself, otherwise someone else will do it',
            text: 'Everyone can stumble. Only the strongest can laugh at it. Stop being so serious with this self-irony mini course.',
            category: 'type-3',
        },
        {
            image: slot32,
            title: 'How to enhance your creativity aptitudes',
            text: 'In 20 minutes, discover new ways to get your creative juices flowing. Learn how to get out of a rut in case you get stuck in it.',
            category: 'type-3',
        },
        {
            image: slot33,
            title: '“Camera, action!” - How to stop being afraid of camera?',
            text: "Learn Special how to deal with the audience, and speak on camera, during the interview, or on TV with FeelGrow.AI's mini-course.",
            category: 'type-3',
        },
        {
            image: slot34,
            title: 'Happiness: Positive psychology and the science of happiness',
            text: 'Where does true happiness come from? Find out more in this course.',
            category: 'type-3',
        },
        {
            image: slot35,
            title: 'Happiness: Positive psychology and the science of happiness',
            text: 'Where does true happiness come from? Find the key to your joyful wellbeing in our FeelGrow.AI mini-course.',
            category: 'type-3',
        },
        {
            image: slot36,
            title: 'How to enhance your creativity aptitudes',
            text: 'In 20 min, discover new ways to get your creative juices flowing. Learn how to get out of a rut if you get stuck in it.',
            category: 'type-3',
        },
        {
            image: slot37,
            title: '10 Life-changing ideas by Robin Sharma',
            text: 'This course is about 10 life-changing ideas about leadership and motivation, that you should know.',
            category: 'type-3',
        },
        {
            image: slot41,
            title: 'How to evolve visionary leadership',
            text: 'Do you have what it takes to lead others to success? That’s what visionary leadership is all about. Join this class and develop leadership skills.',
            category: 'type-4',
        },
        {
            image: slot42,
            title: 'How to manage a team of creatives and succeed?',
            text: 'Are you searching for ways to manage your team of creatives better? This course is about the management strategies you need to use to promote creativity in your team.',
            category: 'type-4',
        },
        {
            image: slot43,
            title: 'Fundamental pillars of leadership',
            text: 'Do you want to become a better leader? Take this minicourse and acquire the skills you need to convince people to grow your business, community, or organization.',
            category: 'type-4',
        },
        {
            image: slot44,
            title: 'Interpersonal Influence',
            text: 'In 10 minutes, you’ll learn how to sell your ideas, and influence others into agreeing with you. Additionally, discover how best to communicate in different contexts.',
            category: 'type-4',
        },
        {
            image: slot45,
            title: 'Personal brand: Creation and promotion of a public image',
            text: 'Changing the world on your own seems unachievable? Start from scratch, build audience and personal influence.',
            category: 'type-4',
        },
        {
            image: slot46,
            title: 'Leadership: Lessons of effective managers',
            text: 'This course is about fundamental theories and leader styles, decision-making, liability, and proactiveness.',
            category: 'type-4',
        },
        {
            image: slot47,
            title: 'Mass communications',
            text: 'You will find out the secrets of bloggers and politicians for effective communication with an impersonal audience, discover how to influence a broad audience.',
            category: 'type-4',
        },
        {
            image: slot51,
            title: 'Speak so people want to listen',
            text: 'In 15 minutes, you’ll learn how to grab the attention of anyone you speak to, and how to keep them interested in what you have to say.',
            category: 'type-5',
        },
        {
            image: slot52,
            title: 'How to speak confidently and deliver your message',
            text: 'Speaking confidently is good for effective communication. It lets you connect with your audience. You will learn how to deliver a speech without a fuss.',
            category: 'type-5',
        },
        {
            image: slot53,
            title: 'How to improve your presentation skills?',
            text: 'Do you fear presenting in the public? This is a common problem many people experience. Join this class and learn how to present your ideas confidently!',
            category: 'type-5',
        },
        {
            image: slot54,
            title: 'Types of communicators: How do you communicate?',
            text: 'Categorize people, through communication patterns. Learn how to chat or negotiate with each of them.',
            category: 'type-5',
        },
        {
            image: slot55,
            title: 'Oratorical art',
            text: "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
            category: 'type-5',
        },
        {
            image: slot56,
            title: 'Public speaking basics',
            text: 'More than 70% of the population is fearful of oral presentations. Enhance your communication skills!',
            category: 'type-5',
        },
        {
            image: slot57,
            title: 'Adaptors: Do they help or hinder adaptation?',
            text: 'How to use adaptor-gestures in communication. Do they really help in stressful situations? Discover essential elements of non-verbal communications in this mini-course.',
            category: 'type-5',
        },
        {
            image: slot61,
            title: 'How to manage stress a handle emotions in your favor',
            text: 'You are low on finances, debtors are calling, and struggling with a bad boss? These events can induce anxiety. Learn how to control them in this course.',
            category: 'type-6',
        },
        {
            image: slot62,
            title: 'Perform under pressure with ease',
            text: 'Are you tired of stress overburdening your mind with never-ending worries? There is an escape. This course provides the much help you need to attain peace of mind.',
            category: 'type-6',
        },
        {
            image: slot63,
            title: 'Biohacking: How to rest to relax',
            text: 'This course will help you to understand your body and your feelings better to improve your life at all levels.',
            category: 'type-6',
        },
        {
            image: slot64,
            title: 'Healthy sleep',
            text: 'Get acquainted with the basics of healthy sleep, discover the physiology of this process.',
            category: 'type-6',
        },
        {
            image: slot65,
            title: "Thomas's theorem, or How do you predict your own happiness?",
            text: 'How to predict your own happiness? Learn about the Thomas theorem.',
            category: 'type-6',
        },
        {
            image: slot66,
            title: 'Emotional intelligence',
            text: 'Learn to motivate yourself with ordinary thoughts. A person who controls his/her emotions enjoys life!',
            category: 'type-6',
        },
        {
            image: slot67,
            title: 'Mental self-regulation',
            text: 'Learn how to cope with stress at work and school, difficult relations in a team, fear before important events, apathy, difficulty taking the first step and procrastination.',
            category: 'type-6',
        },
        {
            image: slot71,
            title: 'Negotiating',
            text: 'The course is about effective interaction to find mutually beneficial problem-solving and goal achievement.',
            category: 'type-7',
        },
        {
            image: slot72,
            title: 'Recharge your career with accountability',
            text: 'In 10 minutes, you’ll learn how to take responsibility in the workplace, and how to become indispensable to your employer.',
            category: 'type-7',
        },
        {
            image: slot73,
            title: 'Career development',
            text: 'You will learn career counseling, job search, CV writing, passing interviews, and team adaptation.',
            category: 'type-7',
        },
        {
            image: slot74,
            title: 'Healthy sleep',
            text: 'Get acquainted with the basics of healthy sleep, discover the physiology of this process.',
            category: 'type-7',
        },
        {
            image: slot75,
            title: 'Modern rhetoric',
            text: 'In 8 weeks of this program, you will master and learn how to apply traditional and modern rhetoric techniques for speeches, presentations, vlogging, stand-ups etc.',
            category: 'type-7',
        },
        {
            image: slot76,
            title: 'Top 10 hobbies to develop soft skills',
            text: 'Master your soft skills through your daily routine, by in-building hobbies into your usual activity.',
            category: 'type-7',
        },
        {
            image: slot77,
            title: 'Team building',
            text: 'You will learn how to build a team and improve its effectiveness. The course on team building will help you understand all the hidden rocks and distinctive features.',
            category: 'type-7',
        },
        {
            image: slot81,
            title: 'Financial literacy',
            text: 'You will learn how the financial system works, how to earn money, and manage it.',
            category: 'type-8',
        },
        {
            image: slot82,
            title: 'Stop living from paycheck to paycheck and start investing',
            text: 'Do you struggle to make ends meet? Join this class and develop financial acumen.',
            category: 'type-8',
        },
        {
            image: slot83,
            title: 'How to get out of the debt trap',
            text: 'In 10 minutes, you’ll learn how to avoid bad credit and navigate your way out of debt traps and how best to manage your income and expenses.',
            category: 'type-8',
        },
        {
            image: slot84,
            title: 'Budgeting of FeelGrow.AI',
            text: 'Start planning a monthly budget, increasing your savings with the budgeting 101 mini-course.',
            category: 'type-8',
        },
        {
            image: slot85,
            title: '5 Ways to break the financial ceiling and start making money',
            text: 'This course will show you a working strategy to expand your financial horizons right from the start!',
            category: 'type-8',
        },
        {
            image: slot86,
            title: 'Sales',
            text: 'You will learn what sales are and who is a professional sales manager, what are sales techniques, how to learn how to sell and achieve success in this business.',
            category: 'type-8',
        },
        {
            image: slot87,
            title: 'Business process modeling: What is it and why is it needed?',
            text: "Do you need to project future business metrics? You won't avoid modeling your business processes.",
            category: 'type-8',
        },
        {
            image: slot91,
            title: 'Critical thinking techniques and methods',
            text: 'How do you think critically? Is it something you are born with, or is it learned? Well, it can be learned. This course teaches how to think critically.',
            category: 'type-9',
        },
        {
            image: slot92,
            title: 'The art of problem-solving for anyone',
            text: 'The art of problem-solving is a necessary skill that each one needs for daily living. Enroll in this course and learn how to break huge problems into small tasks',
            category: 'type-9',
        },
        {
            image: slot93,
            title: 'Decision making',
            text: 'A lot of practical techniques that facilitate human activities in everyday life, in training and at work.',
            category: 'type-9',
        },
        {
            image: slot94,
            title: 'Profiling. Understanding human psychology',
            text: 'You will learn to analyze people, their expressions, gestures, and actions, to make a psychological profile.',
            category: 'type-9',
        },
        {
            image: slot95,
            title: 'Сonflictology',
            text: 'The goal of this course is to teach you to interact with people around you more effectively.',
            category: 'type-9',
        },
        {
            image: slot96,
            title: 'TRIZ - Theory of inventive problem solving',
            text: 'You will learn techniques and algorithms for inventive problem solving using the TRIZ methodology and how to apply the skills of effective invention in your work.',
            category: 'type-9',
        },
        {
            image: slot97,
            title: 'TRIZ in practice (Creative approach)',
            text: 'TRIZ (theory of inventive problem solving) is a direction that helps people of science and creativity to solve difficult inventive problems.',
            category: 'type-9',
        },
        {
            image: slot101,
            title: 'Cognitive science. Thinking development',
            text: 'In 2 months you will learn how to apply more than 20 thinking techniques in your life using special brain training.',
            category: 'type-10',
        },
        {
            image: slot102,
            title: 'Develop perseverance and resilience with these easy steps',
            text: 'Are you tired of giving up when things get tough? Join this class and learn how to cope with it and follow through with your goals to be successful.',
            category: 'type-10',
        },
        {
            image: slot103,
            title: 'System thinking basics',
            text: 'Did you know that system thinking makes you smarter? This course will teach you to see the big picture of a system.',
            category: 'type-10',
        },
        {
            image: slot104,
            title: 'How to brainstorm?',
            text: 'Did you know that brainstorming can help you to think out of the box? Great ideas can be conceptualized with brainstorming methods and strategies.',
            category: 'type-10',
        },
        {
            image: slot105,
            title: 'Brain training: How to build cognitive flexibility',
            text: 'In 15 minutes, learn how to adapt to changing or unexpected situations, and remain calm and innovative under pressure.',
            category: 'type-10',
        },
        {
            image: slot106,
            title: 'Interpersonal skills: Team communication skills course',
            text: 'Do you want to be good at interpersonal skills? This course will guide you on how to improve your team communication skills to become a better leader.',
            category: 'type-10',
        },
        {
            image: slot107,
            title: 'How to improve adaptability in 10 easy steps',
            text: 'Wonder how to train your ability to adjust to diverse conditions? Learn & apply tactics from FeelGrow.AI mini-course.',
            category: 'type-10',
        },
    ]);

    

    const [currentTab, setCurrentTab] = useState('type-1');

    const changeTab = (type:string) => {
        setCurrentTab(type);
    }

    return (
        <>
            <div className="container">
                <div className="titleCorpSpec center">Develop your skills with short courses <br style={{display: 'block'}} /> Only structured knowledge in 25 minutes</div>
                {/* <div className="homeStatDesc">Some useful skills and tools are rarely taught at school or university. That is why we have created the <br /> FeelGrow.AI project so you could learn these skills</div> */}
            </div>

            <div id={'skills'} className="container LearnSpecialWrap">
                <div className="LearnSpecialTabsBox">
                    <div className="LearnSpecialTabs">
                        {
                            tabs.map((key, index) => (
                                <div 
                                    key={index}
                                    onClick={() => changeTab(key.type)} 
                                    className={`LearnSpecialTab ${(key.type === currentTab) ? 'active' : ''}`}>{key.name}</div>
                            ))
                        }
                    </div>
                </div>

                <div className="LearnSpecialBox">

                    <Slider initialSlide={0} {...settings} className="LearnSpecialSlider">
                        {
                            items.map((key, index) => {
                                if(key.category === currentTab){
                                    return <a href={fullLink} className={`LearnSpecialItem`} key={index}>
                                        <div className="LearnSpecialImage">
                                            <img src={key.image} alt="" />
                                        </div>
                                        <div className="LearnSpecialTextinfo">
                                            <span>{key.title}</span>
                                            <p>{key.text}</p>
                                        </div>
                                    </a>
                                }  
                            })
                        }
                    </Slider>
                    
                    <div className="LearnSpecialSliderInfo">
                        <span className="LearnSpecialCurr"><b>{currSlide}</b> /</span>
                        <span className="LearnSpecialAll">7</span>
                    </div>

                </div>

                {/* <div className="LearnSpecialBoxMobile">
                    <div className="LearnSpecialBoxMobileScroll">
                        {
                            items.map((key, index) => (
                                <div className={`LearnSpecialItem LearnSpecialItemMobile`} key={`mobil-${index}`}>
                                    <div className="LearnSpecialImage">
                                        <img src={key.image} alt="" />
                                    </div>
                                    <div className="LearnSpecialTextinfo">
                                        <span>{key.title.substring(0, 30).concat('...')}</span>
                                        <p>{key.text.substring(0, 20).concat('...')}</p>
                                        <button className="LearnSpecialBoxMobileBtn" onClick={() => navigate(fullLink)}>Try FeelGrow.AI</button>
                                        <p>Pricing - Free</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div> */}
            </div>

            <div className="buttonBlock4">
                <Link to={fullLink} className="btnv2 btn-primary noEventsInside" id={`pageCorpTry${pagePath}BtnFirstSecond`}>
                    <span>Try FeelGrow.AI</span>
                </Link>
            </div>
        </>
            
    )
}

export default memo(LearnBlockFeelGrow);
