import './styles.scss';
import React, {memo, useMemo} from 'react';
import {StatisticGridComponent} from "../../types/ApiComponent";
import {
    aiScreen1,
    aiScreen2
} from "../../images";

type Props = {
    data: StatisticGridComponent
}

const AIAeerFeelGrow = ({ data }: Props) => {

    // const renderGrid = useMemo(() => {
    //     return data.Blocks.map((block, index) => (
    //         <div key={`item-${index}`} className="statisticBlockItem">
    //             <div className="statisticBlockVal">{block.Value}</div>
    //             <div className="statisticBlockCap">{block.Caption}</div>
    //         </div>
    //     ))
    // },[data])

    return (
        // <div className="container statisticBlock">
        //     <div className="statisticBlockTitle">Millions of users will be training with us</div>
        //     <div className="statisticBlockGrid">
        //         {renderGrid}
        //     </div>
        // </div>
        <div className="container aiAeerSpecial" id="aiAeer">
            <div className="container">
                <div className="titleCorpSpec center">Artificial intelligence FeelGrow.AI</div>
                <div className="descriptionCorpSpec center">FeelGrow.AI - your personal assistant that will help to develop skills, fulfill tasks, reach goals, <br /> manage finances, design future and many more</div>

                <div className="aiAeerSpecialBox">
                    <div className="aiAeerSpecialScreen">
                        <img src={aiScreen1} alt="" />
                    </div>
                    <div className="aiAeerSpecialScreen">
                        <img src={aiScreen2} alt="" />
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default memo(AIAeerFeelGrow);
