// import '../HomeScreenshots2/styles.scss';

import React, {memo, useMemo, useState} from 'react';
import {AppScreenShotsComponent} from "../../types/ApiComponent";
import {WITH_API_BASE} from "../../configs/appConfigs";
import {
    specScreen1,
    specScreen2,
    specScreen3,
    specScreen4,
    specScreen5,
    specScreen6
} from "../../images";
import Slider from "react-slick";
import {Link} from "gatsby";

type Props = {
    data: AppScreenShotsComponent,
    fullLink: string,
    pagePath: string
}

const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 1,
};
const settingsMob = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1
};

const HomeScreenshotsFeelGrow = ({data, fullLink, pagePath}: Props) => {
    // const renderList = useMemo(() => {
    //     return data.Blocks.map((screenshot, index) => {
    //         return (
    //             <div className="appScreenshots2Screen" key={index}>
    //                 <div className="appScreenshots2Title">{screenshot?.Title || ''}</div>
    //                 <div className="appScreenshots2Image">
    //                     <img src={WITH_API_BASE(screenshot.Image.data.attributes.url)} alt=""/>
    //                 </div>
    //             </div>
    //         )
    //     })
    // }, [data]);

    // const renderMobileList = useMemo(() => {
    //     return data.Blocks.map((screenshot, index) => {
    //         return (
    //             <div className="appScreenshotsScreenMob" key={index}>
    //                 <div className="appScreenshotsScreenMobInner">
    //                     <div className="appScreenshotsTitle">{screenshot?.Title || ''}</div>
    //                     <div className="appScreenshotsImage">
    //                         {
    //                             screenshot?.MobImage?.data ? (
    //                                 <img src={WITH_API_BASE(screenshot.MobImage.data.attributes.url)} height={336} alt=""/>
    //                             ) : (
    //                                 <img src={WITH_API_BASE(screenshot.Image.data.attributes.url)} height={336} alt=""/>
    //                             )
    //                         }
    //                     </div>
    //                 </div>
    //             </div>
    //         )
    //     })
    // }, [data]);

    const [list, setList] = useState([
        {
            id: 1,
            title: 'Use our AI-powered bot to reinforce your learning',
            image: specScreen1,
        },
        {
            id: 2,
            title: 'Manage your work and life to-do lists',
            image: specScreen2,
        },
        {
            id: 3,
            title: 'Skill up to success. Grow professionally and personally with user-friendly platform',
            image: specScreen3,
        },
        {
            id: 4,
            title: 'Build good habits and achieve your goals faster',
            image: specScreen4,
        },
        {
            id: 5,
            title: 'Capture life as you live in, take notes to retain more knowledge or record your activities with Diary',
            image: specScreen5,
        },
        {
            id: 6,
            title: 'Take control of your budget. Stay on top of your finance',
            image: specScreen6,
        },
    ])

    const renderList = useMemo(() => {
        return list.map((screenshot, index) => {
            return (
                <div className="appScreenshots2Screen" key={index}>
                    <div className="appScreenshots2Title big">{screenshot?.title || ''}</div>
                    <div className="appScreenshots2Image">
                        <img src={screenshot.image} alt=""/>
                    </div>
                </div>
            )
        })
    }, [data]);

    const renderMobileList = useMemo(() => {
        return list.map((screenshot, index) => {
            return (
                <div className="appScreenshotsScreenMob" key={index}>
                    <div className="appScreenshotsScreenMobInner">
                        <div className="appScreenshotsTitle big">{screenshot?.title || ''}</div>
                        <div className="appScreenshotsImage">
                            {
                                screenshot?.image ? (
                                    <img src={screenshot.image} height={336} alt=""/>
                                ) : (
                                    <img src={screenshot.image} height={336} alt=""/>
                                )
                            }
                        </div>
                    </div>
                </div>
            )
        })
    }, [data]);

    return (
        <>
            <div className="appScreenshots2 nodots mb20" id={`slider${pagePath}`}>
                <Slider initialSlide={0} {...settings} className="appScreenshots2ScreenPc">
                    {renderList}
                </Slider>
            </div>

            <div className="container mobAppScreenshots2 nodots mb20" id={`slider${pagePath}`}>
                <Slider initialSlide={0} centerMode variableWidth adaptiveHeight {...settingsMob} className="appScreenshots2ScreenMobile">
                    {renderMobileList}
                </Slider>
            </div>

            {/* <div className="buttonBlock">
                <Link to="/payment" className="btn btn-primary">
                    <img src={CrownImage} className="btn-img" width={21} alt=""/>
                    <span>Try it for $1</span>
                </Link>
            </div> */}

            {/* <div className="buttonBlock2">
                <Link to="/payment" className="btnv2 btn-primary">
                    <img src={Crown2} className="btn-img" width={30} alt=""/>
                    <span>Try FeelGrow.AI</span>
                    <img src={arrRight2} className="btn-img" width={30} alt=""/>
                </Link>
            </div> */}

            <div className="buttonBlock4">
                <Link to={fullLink} className="btnv2 btn-primary noEventsInside" id={`pageCorpTry${pagePath}BtnFirst`}>
                    <span>Try FeelGrow.AI</span>
                </Link>
            </div>
            
        </>
    )
}

export default memo(HomeScreenshotsFeelGrow)
