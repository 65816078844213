import './styles.scss';
import React, {memo} from 'react';

import {
    AvatarImage,
    LogoFeelGrow,
    masterCardV2,
    VisaV2,
    AppStoreV2,
    GPlayV2
} from "../../images";
import { Link } from 'gatsby';

type Props = {
    fullLink: string,
    pagePath: string,
    btnType: string
}

const MainFooterFeelGrow = ({pagePath, btnType, fullLink}: Props) => {

    return (
        <footer className="container footer" id={'contacts'}>
            <div className="footerFeelLeft">
                <div className="footerFeelLeftAvatar">
                    <img src={AvatarImage} width={96} height={96} alt=""/>
                </div>
                <div className="footerFeelLeftData">
                    <div className="footerFeelLeftPosition">СЕО</div>
                    <div className="footerFeelLeftName">Andrew Filatov</div>
                    <div className="footerFeelLeftDesc">
                        My goal is to create a product that helps people improve themselves
                    </div>
                </div>
            </div>

            <div className="footerFeelCenter">
                <div className="footerFeelPayments">
                    <div>
                        <img src={masterCardV2} width={24} alt=""/>
                    </div>
                    <div>
                        <img src={VisaV2} width={24} alt=""/>
                    </div>
                </div>
                <div className="footerFeelStores">
                    <div>
                        {
                            (btnType === 'appStore') ? (
                                <Link 
                                    to={fullLink}
                                    id={`appStore${pagePath}Link`}
                                    className="noEventsInside">
                                    <img width={168} src={AppStoreV2} alt=""/>
                                </Link>
                            ) : (
                                <a 
                                    onClick={() => showForm(true)}
                                    id={`appStore${pagePath}Link`}
                                    className="noEventsInside">
                                    <img width={168} src={AppStoreV2} alt=""/>
                                </a>
                            )
                        }
                        
                    </div>
                    <div>
                        {
                            (btnType === 'appStore') ? (
                                <Link to={fullLink} id={`GPlay${pagePath}Link`}
                                    className="noEventsInside">
                                    <img width={167} src={GPlayV2} alt=""/>
                                </Link>
                            ) : (
                                <a 
                                    onClick={() => showForm(true)}
                                    id={`GPlay${pagePath}Link`}
                                    className="noEventsInside">
                                    <img width={167} src={GPlayV2} alt=""/>
                                </a>
                            )
                        }
                    </div>
                </div>
                <div className="footerFeelLinks">
                    <Link to="/terms" id={`terms${pagePath}link`}>Terms of User</Link>
                    <Link to="/privacy" id={`privacy${pagePath}link`}>Privacy policy</Link>
                    <Link to="/cookie" id={`cookie${pagePath}link`}>Cookie policy</Link>
                    <Link to="/cancel" id={`cancel${pagePath}link`}>Unsubscribe</Link>
                </div>
            </div>

            <div className="footerFeelRight">
                <img width={70} className="footerFeelLogo" src={LogoFeelGrow} alt=""/>
                <div className="footerFeelCopy">
                    &copy; AEER INC
                </div>
                <address className="footerFeelAddress">
                    8 THE GREEN STE A DOVER, DE 19901
                </address>
            </div>
        </footer>
    )
}

export default memo(MainFooterFeelGrow);
