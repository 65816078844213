import './styles.scss';
import React, {memo, useState} from 'react';

import {
    BigLogoImage,
    LogoFeelGrow,
    lang
} from "../../images";
import { Link } from 'gatsby';
import {AnchorLink} from "gatsby-plugin-anchor-links";
import ChooseLanguage from '../ChooseLanguage';

type Props = {
    toggleMenu: any,
    pagePath: string,
    fullLink: string
}

const HeaderFeelGrow = ({toggleMenu, pagePath, fullLink}: Props) => {

    const [modalStatus, setModalStatus] = useState(false);
    const showForm = (status:boolean) => {
        setModalStatus(status);
    }

    return (
        <header>
            <div className="header container">
                <div className="headerLogo2">
                    <Link to="/">
                        <img src={LogoFeelGrow} alt=""/>
                    </Link>
                </div>
                <button onClick={() => toggleMenu()} className="mobileBurger">
                    <span></span><span></span>
                </button>
                <div className="headerNav2">
                    <nav>
                        <ul>
                            <li ><AnchorLink to="#tools" ><span id={`tools${pagePath}Link`}>Tools</span></AnchorLink></li>
                            <li ><AnchorLink to="#skills" ><span id={`skills${pagePath}Link`}>Courses</span></AnchorLink></li>
                            <li ><AnchorLink to="#aiAeer" ><span id={`aiAeer${pagePath}Link`}>Our AI Assistant</span></AnchorLink></li>
                            <li ><AnchorLink to="#contacts" ><span id={`contacts${pagePath}Link`}>Contacts</span></AnchorLink></li>
                        </ul>
                    </nav>
                </div>
                {/* <div className="headerLang headerLangFlex" onClick={() => showForm(true)}>
                    <img src={lang} alt="" />
                    <span>Eng</span>
                </div> */}
                <a href={fullLink} className="headerStartButton">Get Started</a>
            </div>

            <ChooseLanguage modalStatus={modalStatus} showForm={showForm} />
        </header>
    )
}

export default memo(HeaderFeelGrow);