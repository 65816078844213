import './styles.scss';
import React, {memo, useEffect, useMemo} from 'react';
import {WITH_API_BASE} from "../../configs/appConfigs";
import {LearnGridComponent} from "../../types/ApiComponent";
import {Crown2, arrRight2, apple} from "../../images";
import {Link} from "gatsby";

type Props = {
    data: LearnGridComponent,
    pagePath: string,
    fullLink: string,
}

const LearnBlockListFeelGrow = ({data, pagePath, fullLink}: Props) => {
    const renderBlocks = useMemo(() => {
        return data.Blocks.map((block, index) => (
            <div key={`item-${index}`} className="learnBlock4">
                <div className="learnBlock4Image">
                    <img src={WITH_API_BASE(block?.ImageLink || '')} width={204} alt=""/>
                </div>
                <div className="learnBlock4Title">
                    {block.Title}
                </div>
                <div className="learnBlock4Desc">
                    {block.Description}
                </div>
            </div>
        ))
    }, [data])

    return (
        <>
            <div id={'skills'}>
                <div className="homeStatTitleBox container">
                    {/* <div className="homeStatTitle">Learn in-demand skills and achieve your goals <br /> faster with over 150 courses</div>
                    <div className="homeStatDesc">Save 2 years of training and get the most in-demand skills now. <br /> Learn and use them in your daily life. Enjoy your growth and changes.</div> */}
                    <div className="titleCorpSpec center">Get all the resources for success <br style={{display: "block"}} /> with the FeelGrow.AI platform</div>
                    <div className="descriptionCorpSpec center">Save 2 years of training and get the most in-demand skills now. Learn and use them in your daily life. Enjoy your growth and changes.</div>
                </div>
                
                <div className="learnBlock4Scroll">
                    <div className="learnBlock4ScrollInside">
                        {renderBlocks}
                    </div>
                </div>
            </div>
            <div className="buttonBlock4">
                <Link to={fullLink} className="btnv2 btn-primary noEventsInside" id={`pageCorpTry${pagePath}BtnThird`}>
                    <span>Try FeelGrow.AI</span>
                </Link>
            </div>
        </>
    )
}

export default memo(LearnBlockListFeelGrow);
