import './styles.scss';
import React, {memo} from 'react';
import {HomeOverviewComponent} from "../../types/ApiComponent";
import {WITH_API_BASE} from "../../configs/appConfigs";
import { Link } from 'gatsby';

type Props = {
    fullLink: string,
    data: HomeOverviewComponent
}

const HomeOverviewFeelGrow = ({data, fullLink}: Props) => {
    return (
        <div className="container" id={'tools'}>
            <div className="homeOverviewSpecial">
                <div className="homeOverviewSpecialBox p-small">
                    <div className="homeOverviewSpecialLeft pl0">
                        <div className="homeOverviewSpecialTitle">
                            TRANSFORM YOUR LIFE WITH FeelGrow.AI
                        </div>
                        <div className="homeOverviewSpecialDesc">FeelGrow.AI is AI-powered interactive soft skills training courses & productivity tools in one app</div>
                        <Link to={fullLink} className="homeOverviewSpecialBtn">TRY FeelGrow.AI</Link>
                    </div>
                    <div className="homeOverviewSpecialImage">
                        <img src={WITH_API_BASE(data.BgImage?.data?.attributes?.url || '')} width={684} height={511} alt=""/>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default memo(HomeOverviewFeelGrow);
